.Checkbox {
  input {
    display: none;
  }

  .form-check {
    padding: 0;
  }

  .Checkbox__lable {
    border-radius: 0.25rem;
    background-color: $red;
    padding: 5px;
    width: min(50px, 100%);
    color: $white;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  &--active {
    .Checkbox__lable {
      background-color: $green;
    }
  }
}
