/* font style */
.italic {
  font-style: italic;
}

/* font weight */
.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

/* text size */
.text-xxs {
  font-size: 0.6875rem;
  line-height: 0.9rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.375rem; /* original tailwind uses 1.25 */
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2rem; /* original tailwind uses 2.25 */
  line-height: 2.5rem;
}

/* text alignment */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* text decoration */
.underline {
  text-decoration: underline;
}

/* text transform */
.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

@media (min-width: 640px) {
  .sm\:font-semibold {
    font-weight: 600;
  }

  .sm\:font-bold {
    font-weight: 700;
  }

  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.25rem; /* original tailwind uses 1.75 */
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:whitespace-nowrap {
    white-space: nowrap;
  }
}

@media (min-width: 768px) {
  .md\:font-semibold {
    font-weight: 600;
  }

  .md\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-4xl {
    font-size: 2rem; /* original tailwind uses 2.25 */
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 2.5rem; /* original tailwind uses 2.5rem */
    line-height: 3rem;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-left {
    text-align: left;
  }
}
