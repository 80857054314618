@import "../base/variables.scss";

/* borders */
.border-0 {
  border-width: 0px;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.rounded-none {
  border-radius: 0px;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

@media (min-width: 640px) {
  .sm\:rounded-lg {
    border-radius: 0.5rem;
  }
}

@media (min-width: 768px) {
  .md\:border-0 {
    border-width: 0px;
  }

  .md\:border-t {
    border-top-width: 1px;
  }

  .md\:border-l {
    border-left-width: 1px;
  }

  .md\:border-b {
    border-bottom-width: 1px;
  }

  .md\:border-l-3 {
    border-left-width: 3px;
  }

  .md\:border-l-4 {
    border-left-width: 4px;
  }

  .md\:rounded-lg {
    border-radius: 0.5rem;
  }
}
