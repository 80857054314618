.Table {
  width: 100%;
}

.Table--w-max {
  width: unset;
  min-width: max-content;
}

.Table--fixed {
  table-layout: fixed;
}

.Table-cell,
.Table-footCell {
  border-top: 1px solid;
  font-size: 11px;
  padding: 5px 5px;
  text-align: left;
}

.Table-cell {
  border-top-color: $light;
}

.Table-footCell {
  border-top-color: $gray;
}

.Table-headCell {
  color: $secondary;
  fill: currentColor;
  font-size: 0.75rem;
  padding: 10px 5px;
  font-weight: 400;
  padding-bottom: 8px;
  vertical-align: bottom;
  text-align: left;
}

.Table-headContent {
  display: inline-block;
  position: relative;
}

.Table-pageSizeSelect {
  max-width: max-content;
  min-width: 160px;
  padding-top: 0;
  position: absolute;
  right: 0;
}

.Table-sortMark {
  bottom: 4px;
  position: absolute;
  right: 0;
}

.Table-headCell--sortable {
  cursor: pointer;
}

.Table-sortUp, .Table-sortDown {
  fill: $secondary;
}

.Table-headCell--up .Table-sortUp {
  fill: $blue;
}

.Table-headCell--down .Table-sortDown {
  fill: $blue;
}

.Table--sortable .Table-cell,
.Table--sortable .Table-footCell,
.Table--sortable .Table-headContent {
  padding-right: 1rem;
}
