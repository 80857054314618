.ColorLabel {
  display: flex;
  justify-content: space-between;

  &__color {
    display: inline-block;
    border-radius: 0.25rem;
    min-width: 10px;
    min-height: 10px;
    color: $white;
  }
}
