/* display */
.block {
  display: block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.inline-block {
  display: inline-block;
}

@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:inline-block {
    display: inline-block;
  }
}

@media (min-width:768px) {
  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:hidden {
    display: none;
  }
}
