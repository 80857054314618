$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #35b653;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray: #6c757d;
$gray-dark: #343a40;
$primary: #4650dd;
$secondary: #6c757d;
$success: #11D3BC;
$info: #17a2b8;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;
