.Label {
  border-radius: 0.25rem;
  background-color: $red;
  padding: 5px;
  color: $white;
  text-align: center;

  &--active {
    background-color: $green;
  }
}
