/* height */
.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-72 {
  height: 18rem;
}

.h-116 {
  height: 29rem;
}

.max-h-full {
  max-height: 100%;
}

/* box Sizing */
.box-border {
  box-sizing: border-box;
}

/* width */
.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-80 {
  width: 20rem;
}

.w-auto {
  width: auto;
}

.w-5\/12 {
  width: 41.666667%;
}

.w-full {
  width: 100%;
}


.max-w-2\/6 {
  max-width: 33.333333%;
}

.max-w-4\/6 {
  max-width: 66.66%;
}

.max-w-6r { 
  max-width: 6rem;
}

.max-w-7r {
  max-width: 7rem;
}

.max-w-8r {
  max-width: 8rem;
}

.max-w-10r {
  max-width: 10rem;
}

.max-w-12r {
  max-width: 12rem;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.min-w-5\/12 {
  min-width: 41.666667%;
}

.min-w-screen {
  min-width: 100vw;
}

.min-w-6r {
  min-width: 6rem;
}

.min-w-8r { 
  min-width: 8rem;
}

.min-w-12r {
  min-width: 12rem;
}

.min-w-xs {
  min-width: 20rem;
}

.min-w-max {
  min-width: max-content;
}

.max-w-min {
  max-width: min-content;
}

.min-w-screen-lg {
  min-width: 1024px;
}

.min-w-screen-md {
  min-width: 768px;
}

.min-w-screen-xxl {
  min-width: 1400px;
}

/* Z-Index */
.z-1 {
  z-index: 1;
}

.top-0 {
  top: 0px;
}

/* overflow */
.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

@media (min-width: 640px) {
  .sm\:h-auto {
    height: auto;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:max-w-8r {
    max-width: 8rem;
  }

  .sm\:max-w-12r {
    max-width: 12rem;
  }

 .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:min-w-5\/12 {
    min-width: 41.6666%;
  }

  .sm\:min-w-0 {
    min-width: 0px;
  }

  .sm\:min-w-max {
    min-width: max-content;
  }
}

@media (min-width: 768px) {
  .md\:max-h-32 {
    max-height: 8rem;
  }

  .md\:w-6\/12 {
    width: 50%;
 }

  .md\:w-5 {
    width: 5rem;
  }

  .md\:w-40 {
    width: 10rem;
  }

  .md\:max-w-18r {
    max-width: 18rem;
  }

  .md\:max-w-12r {
    max-width: 12rem;
  }

  .md\:max-w-sm {
    max-width: 24rem;
  }

  .md\:max-w-lg {
    max-width: 32rem;
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:max-w-max {
    max-width: max-content;
  }

  .md\:min-w-md {
    min-width: 28rem;
  }

  .md\:min-w-auto {
    min-width: auto;
  }
}

@media (min-width: 1024px) {
  .lg\:min-w-md {
    min-width: 28rem;
  }
}
