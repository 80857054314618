.Point {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray-dark;
    text-align: center;
    border-radius: 50%;
    color: #000000;
    text-decoration: none;
}

.Point--link {
    cursor: pointer;
    transition: all 0.3s ease;
    transition-property: color, border-color, border-width;
    &:hover {
        color: $white;
        border-color: $primary;
        border-width: 12.5px;
    }
}
