/* full margins */
.m-0 {
  margin: 0;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

/* double margins */
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

/* single margins */
.ml-auto {
  margin-left: auto;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-13 {
  margin-left: 3.25rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mr-14 {
  margin-right: 3.5rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mr-20 {
   margin-right: 5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.ml-32 {
  margin-left: 8rem;
}

.mr-40 {
  margin-right: 10rem;
}

.mt-44 {
  margin-top: 11rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

@media (min-width: 640px) {
  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mr-0 {
    margin-right: 0;
  }

  .sm\:mt-1 {
    margin-top: 0.25rem;
  }

  .sm\:mt-2 {
    margin-top: 0.5rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:mt-16 {
    margin-top: 2.5rem;
  }

  .sm\:mb-25 {
    margin-bottom: 6.25rem;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  /* md double margins */
  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  /* md single margins */
  .md\:-mt-10 {
    margin-top: -2.5rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mr-3 {
    margin-right: 0.75rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mt-5 {
    margin-top: 1.25rem;
  }

  .md\:mr-5 {
    margin-right: 1.25rem;
  }

  .md\:ml-5 {
    margin-left: 1.25rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mt-14 {
    margin-top: 3.5rem;
  }

   .md\:ml-14 {
    margin-left: 3.5rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mr-20 {
    margin-right: 5rem;
  }

  .md\:mr-28 {
    margin-right: 7rem;
  }

  .md\:mt-44 {
    margin-top: 11rem;
  }

  .md\:mb-44 {
    margin-bottom: 11rem;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  /* lg double margins */
  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  /* lg single margins */
  .lg\:-mt-12 {
    margin-top: -3rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mt-18 {
    margin-top: 4.5rem;
  }
}

@media (min-width: 768px) {
  .md\:m-8{
    margin: 2rem;
  }
}
