/* flex */
.inline-flex {
  display: inline-flex;
}

/* order */
.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-first {
  order: -9999;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-col {
  flex-direction: column;
}

.flex-none {
  flex: none;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-center {
  display: flex;
  align-items: center;
}

/* align-items */
.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

/* justify-content */
.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

@media (min-width: 640px) {
  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:flex-initial {
    flex: 0 1 auto;
  }

  .md\:self-center {
    align-self: center;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-first {
    order: -9999;
  }
}

@media (min-width: 1024px) {
  .lg\:flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1200px) { /* original tailwind 1280px */
  .xl\:order-none {
    order: 0;
  }
}
